// import { parseTime } from '@/plugins/lib/modules/date'
import { status, getType, getLabel } from '@/utils/options';

import { genModels } from '@/plugins/widget/c-form/rules';

export var models = genModels(function (genRules, ctx) {return [
  {
    span: 8,
    kname: 'goodsNo',
    itemProps: {
      label: '货品编号：' } },


  {
    span: 8,
    kname: 'goodsName',
    itemProps: {
      label: '货品名称：' } },


  {
    span: 8,
    kname: 'goodsSource',
    itemProps: {
      label: '货品来源：' } },


  {
    span: 8,
    kname: 'goodsTypeName',
    itemProps: {
      label: '货品分类：' } },


  {
    span: 8,
    kname: 'model',
    itemProps: {
      label: '规格型号：' } },


  {
    span: 8,
    kname: 'goodsNum',
    itemProps: {
      label: '数量：' } },


  {
    span: 8,
    kname: 'prestore',
    itemProps: {
      label: '预存天数：' } },


  {
    span: 8,
    kname: 'goodsLocationNo',
    itemProps: {
      label: '存放位置：' } },


  {
    span: 8,
    kname: 'unit',
    itemProps: {
      label: '计量单位：' } },


  {
    span: 8,
    kname: 'deptName',
    itemProps: {
      label: '货品所属部门：' } },


  {
    span: 8,
    kname: 'status',
    itemProps: {
      label: '货品状态：' } },


  {
    span: 8,
    kname: 'containerName',
    itemProps: {
      label: '货品归属：' } }];});



export var columns = function columns(ctx) {return [
  {
    label: '操作时间',
    kname: 'createTime' },

  {
    label: '操作类型',
    kname: 'operationType' },

  {
    label: '操作账户',
    kname: 'operationAccount' },

  {
    label: '部门',
    kname: 'operationDept' },

  {
    label: '操作人',
    kname: 'operationUser' },

  {
    label: '来源/用途',
    kname: 'goodsSource' }];};


export var formData = function formData() {return {};};